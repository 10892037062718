function mobMenu(){
  var menu = $(".js-menu");
  var shadow = $(".popup-shadow");
  var open = $(".js-menu-open");
  var close = $(".js-menu-close");
  var escBtn = 27;
  open.on("click", function(){
    mobOpen();
  })
  close.on("click", function(){
    mobClose();
  })
  shadow.on("click", function(){
    mobClose();
  })
  $(document).keyup(function(e) {
    if (menu.hasClass('menu-opened') && e.keyCode === escBtn) {
      mobClose();
    }
  })

  function mobOpen(){
    menu.css({
      "transform": "translateX(0)"
    })
    shadow.fadeIn(300);
    if ($(window).width() >= 768) {
      $(".site-content, .js-menu-open, .header__logo").css({
      "filter": "blur(4px)"
      })
    }
    menu.addClass("menu-opened");
    $("body,html").addClass("no-overflow");
    menu.focus();
  }

  function mobClose(){
    menu.css({
      "transform": "translateX(150%)"
    })
    shadow.fadeOut(300);
    $(".site-content, .js-menu-open, .header__logo").css({
      "filter": "none"
    })
    menu.removeClass("menu-opened");
    $("body,html").removeClass("no-overflow");
  }
}
