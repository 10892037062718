function popup(){
  var scrollbarWidth = window.innerWidth - document.body.clientWidth;
  var popup =$(".js-popup");
  var shadow = $(".popup-shadow");
  var open = $(".js-popup-open");
  var close = $(".js-popup-close");
  var escBtn = 27;
  popup.css({
    "display": "none",
    "opacity": "1"
  })
  open.on("click", function(e){
    var vw = $(window).width();
    e.preventDefault();
    openPopup();

  })
  shadow.on("click", function(){
    closePopup();
  })
  close.on("click", function(){
    closePopup();
  })
  $(document).keyup(function(e) {
    if ((popup.css('opacity') == 1 || $('.js-popup-done').css('display') != 'none') && e.keyCode === escBtn) {
      closePopup();
    }
  })


  function openPopup() {
    $(".js-popup").fadeIn();
    $(".popup-shadow").fadeIn();
    $("body").addClass("no-overflow");
    $("body").css({
      "padding-right": scrollbarWidth + "px"
    })
    popup.focus();
  }

  function closePopup() {
    $(".popup").fadeOut();
    $(".popup-shadow").fadeOut();
    $("body").removeClass("no-overflow");
    $("body").css({
      "padding-right": "0"
    })
  }


}
