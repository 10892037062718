function mobMenu(){
  var menu = $(".js-menu");
  var shadow = $(".popup-shadow");
  var open = $(".js-menu-open");
  var close = $(".js-menu-close");
  var escBtn = 27;
  open.on("click", function(){
    mobOpen();
  })
  close.on("click", function(){
    mobClose();
  })
  shadow.on("click", function(){
    mobClose();
  })
  $(document).keyup(function(e) {
    if (menu.hasClass('menu-opened') && e.keyCode === escBtn) {
      mobClose();
    }
  })

  function mobOpen(){
    menu.css({
      "transform": "translateX(0)"
    })
    shadow.fadeIn(300);
    if ($(window).width() >= 768) {
      $(".site-content, .js-menu-open, .header__logo").css({
      "filter": "blur(4px)"
      })
    }
    menu.addClass("menu-opened");
    $("body,html").addClass("no-overflow");
    menu.focus();
  }

  function mobClose(){
    menu.css({
      "transform": "translateX(150%)"
    })
    shadow.fadeOut(300);
    $(".site-content, .js-menu-open, .header__logo").css({
      "filter": "none"
    })
    menu.removeClass("menu-opened");
    $("body,html").removeClass("no-overflow");
  }
}

function tabs(){
  function clear(){
    $(".js-tab.current").removeClass("current");
    $(".js-tab-content.opened").removeClass("opened");
  }
  $(".js-tab").on("click", function(e){
    var sliderReinited = false;
    var target = $(this).data("tab");
    var content = $(".js-tab-content[data-content="+ target +"]");
    if (content.data("slider") != undefined) {
      content.find(".js-slider").slick("unslick");
      content.find(".js-slider").slick({
        "adaptiveHeight" : true
      });
      content[0].removeAttribute("data-slider");
      content.removeData("slider");
    }

    e.preventDefault();
    clear();
    $(this).addClass("current");
    content.addClass("opened");
  })
}

function sliderInit(){
  $(".js-slider").slick();


    $(".js-insta-slider").slick({
      mobileFirst: true,
      dots: false,
      arrows: false,
      variableWidth: true,
      responsive:[
        {
          breakpoint: 768,
          settings: "unslick"
        }
      ]

    })


}

function popup(){
  var scrollbarWidth = window.innerWidth - document.body.clientWidth;
  var popup =$(".js-popup");
  var shadow = $(".popup-shadow");
  var open = $(".js-popup-open");
  var close = $(".js-popup-close");
  var escBtn = 27;
  popup.css({
    "display": "none",
    "opacity": "1"
  })
  open.on("click", function(e){
    var vw = $(window).width();
    e.preventDefault();
    openPopup();

  })
  shadow.on("click", function(){
    closePopup();
  })
  close.on("click", function(){
    closePopup();
  })
  $(document).keyup(function(e) {
    if ((popup.css('opacity') == 1 || $('.js-popup-done').css('display') != 'none') && e.keyCode === escBtn) {
      closePopup();
    }
  })


  function openPopup() {
    $(".js-popup").fadeIn();
    $(".popup-shadow").fadeIn();
    $("body").addClass("no-overflow");
    $("body").css({
      "padding-right": scrollbarWidth + "px"
    })
    popup.focus();
  }

  function closePopup() {
    $(".popup").fadeOut();
    $(".popup-shadow").fadeOut();
    $("body").removeClass("no-overflow");
    $("body").css({
      "padding-right": "0"
    })
  }


}

function priceExtend() {
  var cont = $(".js-price-cont");
  var headerHeight = $(".js-price-header").outerHeight();;
  var blocks = $(".js-price-block");
  var allBlocks = blocks.length;
  var startblocks = 2;
  var startHeight = calculateStartHeight(startblocks);
  var scrollPosition;
  cont.css({
    "height": `${startHeight}px`
  })

  $(".js-prices-extend").on("click", function(){
    
    if (!cont.hasClass("expanded")){
      scrollPosition = $('.js-prices-extend').offset().top - ($(window).height()/2);
      cont.animate({
        "height": `${calculateStartHeight(allBlocks)}px`
      })
      cont.addClass("expanded");
      $(this).text("Свернуть");
    } else {
      cont.animate({
        "height": `${calculateStartHeight(startblocks)}px`
      })
      cont.removeClass("expanded");
      $(this).text("Развернуть");
      $("html,body").animate({scrollTop:scrollPosition});
    }
  })

  function calculateStartHeight(quantity){
    var result;
    var firstBlocksHeight = 0;

    blocks.each(function(index){
      if (index < quantity) {
        firstBlocksHeight = firstBlocksHeight + $(this).outerHeight();
      }
    })
    result = firstBlocksHeight + headerHeight -10;
    return result;
  }
}

function scrollTo() {
  var menu = $(".js-menu");
  var shadow = $(".popup-shadow");
  $(".js-scrollto").on("click", function(e){
    e.preventDefault();
    var target = $(this).attr("href");
    if (menu.hasClass("menu-opened")){
      mobClose();
    }

    $("html,body").animate({scrollTop:$(target).offset().top}, 500);
  })

  function mobClose(){
    menu.css({
      "transform": "translateX(150%)"
    })
    shadow.fadeOut(300);
    $(".site-content, .js-menu-open, .header__logo").css({
      "filter": "none"
    })
    menu.removeClass("menu-opened");
    $("body,html").removeClass("no-overflow");
  }
}





function upload() {

  $(".js-file-input").on("change", function(){
    var cont = $(this).parents(".js-upload-cont");
    var textField = cont.find(".js-upload-text");
    if( this.files) {
      if (this.files.length == 1) {
        textField.text("Выбран 1 файл");
      }
      if (this.files.length > 1 && this.files.length < 5) {
        textField.text(`Выбрано ${this.files.length} файла`);
      }
      if (this.files.length == 5) {
        textField.text("Выбран 5 файлов");
      }
    }
  })
}

function fixedMenu() {
  var lastScrollTop = 0;
  var menuShowed = true;
  var phoneShowed = false;
  var menu = $(".header");
  var phoneBlock = $('.phone-block');
  var footer = $('.site-footer');
  var vw = $(window).width();
  if (vw < 768) {
    $(window).scroll(function(event){
      var st = $(this).scrollTop();
      if (st > 92){
        if (st > lastScrollTop){
          if (menuShowed) {
            menu.addClass("hide");
            menuShowed = false;
            phoneBlock.removeClass("hide");
            footer.addClass('show-phone');
          }
        } else {
          if (!menuShowed) {
            menu.removeClass("hide");
            menuShowed = true;
            phoneBlock.addClass("hide");
            footer.removeClass('show-phone');
          }
        }
      }

       lastScrollTop = st;
    });
  }
  $(window).on("resize", function(){
    if (menu.hasClass("hide") && vw > 768) {
      menu.removeClass("hide")
    }
  })

}

function mask() {
  //maskPlaceholder должна выглядеть так же как phoneOptions.mask
  //только без нулей и фигурных скобок, это важно.
  var maskPlaceholder = "+7 (___) ___-__-__"
  var phoneOptions = {
    mask: '+{7} (000) 000-00-00',
    lazy: false,
    placeholderChar: '_'
  };

  $(".js-input-placeholder").on("click", function(){
    var input = $(this).siblings(".js-input");
    $(this).hide();
    input.css({
      "font-size": "18px"
    }).focus()
  })

  $(".js-input").on("focusout", function(){
    if ($(this).val().length == 0 || $(this).val() == maskPlaceholder) {
      var placeholder = $(this).siblings(".js-input-placeholder");
      $(this).css({
        "font-size": "0"
      })
      placeholder.show();
    }

  })


  var inputPhone = $(".js-phone-mask");
  inputPhone.each(function(){
    var mask = new IMask(this,phoneOptions);
  })


}

function yearUpdate() {
  var year = new Date();
  $(".js-current-year").text(year.getFullYear())

}


$(function() {

  svg4everybody();

  mobMenu();

  tabs();

  sliderInit();

  popup();

  priceExtend();

  scrollTo();

  upload();

  fixedMenu();

  mask();

  yearUpdate();



});



function openPopup(popup) {

  popup.fadeIn();

  $(".popup-shadow").fadeIn();

  $("body").addClass("no-overflow");

}



function closePopup(popup) {

  popup.fadeOut();

  $(".popup-shadow").fadeOut();

  $("body").removeClass("no-overflow");

}


