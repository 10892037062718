function priceExtend() {
  var cont = $(".js-price-cont");
  var headerHeight = $(".js-price-header").outerHeight();;
  var blocks = $(".js-price-block");
  var allBlocks = blocks.length;
  var startblocks = 2;
  var startHeight = calculateStartHeight(startblocks);
  var scrollPosition;
  cont.css({
    "height": `${startHeight}px`
  })

  $(".js-prices-extend").on("click", function(){
    
    if (!cont.hasClass("expanded")){
      scrollPosition = $('.js-prices-extend').offset().top - ($(window).height()/2);
      cont.animate({
        "height": `${calculateStartHeight(allBlocks)}px`
      })
      cont.addClass("expanded");
      $(this).text("Свернуть");
    } else {
      cont.animate({
        "height": `${calculateStartHeight(startblocks)}px`
      })
      cont.removeClass("expanded");
      $(this).text("Развернуть");
      $("html,body").animate({scrollTop:scrollPosition});
    }
  })

  function calculateStartHeight(quantity){
    var result;
    var firstBlocksHeight = 0;

    blocks.each(function(index){
      if (index < quantity) {
        firstBlocksHeight = firstBlocksHeight + $(this).outerHeight();
      }
    })
    result = firstBlocksHeight + headerHeight -10;
    return result;
  }
}
