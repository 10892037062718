function sliderInit(){
  $(".js-slider").slick();


    $(".js-insta-slider").slick({
      mobileFirst: true,
      dots: false,
      arrows: false,
      variableWidth: true,
      responsive:[
        {
          breakpoint: 768,
          settings: "unslick"
        }
      ]

    })


}
