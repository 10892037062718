function tabs(){
  function clear(){
    $(".js-tab.current").removeClass("current");
    $(".js-tab-content.opened").removeClass("opened");
  }
  $(".js-tab").on("click", function(e){
    var sliderReinited = false;
    var target = $(this).data("tab");
    var content = $(".js-tab-content[data-content="+ target +"]");
    if (content.data("slider") != undefined) {
      content.find(".js-slider").slick("unslick");
      content.find(".js-slider").slick({
        "adaptiveHeight" : true
      });
      content[0].removeAttribute("data-slider");
      content.removeData("slider");
    }

    e.preventDefault();
    clear();
    $(this).addClass("current");
    content.addClass("opened");
  })
}
